

switch (location.host) {
  case "192.168.100.5":
    var api_base = 'http://192.168.100.5/multi_tournament_app/api/control_panel/'
    var old_lc_base="http://192.168.100.5/motap_test/lc/"
    break;

  case "127.0.0.1:5503":
    var api_base = 'http://192.168.100.5/multi_tournament_app/api/control_panel/'
    var old_lc_base="http://192.168.100.5/motap_test/lc/"
    break;

  default:
    var api_base = 'https://api.primexop.com/mta/control_panel/'
    var old_lc_base="https://alt.primexop.com/motap/lc/"
}

//   console.log(api_base)



function  go_to_old_lc(){
  location.href = old_lc_base
}



